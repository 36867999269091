<template lang="pug">
.om-onboarding
  router-view
</template>

<script>
  export default {
    created() {
      // HACK: If the user tries to go back to the registration page from
      // the onboarding with the browser back button, sometimes the tab title was invalid.
      // We showed the Registration page/tab title on the Welcome screen
      // This empty title resolves the issue.
      document.title = '';
      document.title = 'OptiMonk Admin';
    },
    mounted() {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, '', window.location.href);
      };
    },
    beforeDestroy() {
      window.onpopstate = null;
    },
  };
</script>
